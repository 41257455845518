import secureLocalStorage from "react-secure-storage";

// secureLocalStorage.setItem("acessTokens", JSON.stringify(acessTokens));

let userData = JSON.parse(secureLocalStorage.getItem("userInfo"));
let highlitedData = JSON.parse(secureLocalStorage.getItem("highlitedData"));
let wish = JSON.parse(secureLocalStorage.getItem("wishlist"));
let cart = JSON.parse(secureLocalStorage.getItem("cartlist"));
let categories = JSON.parse(secureLocalStorage.getItem("categoriesList"));
let visitedProducts = JSON.parse(secureLocalStorage.getItem("visitedProducts"));
let advanceFilters = JSON.parse(secureLocalStorage.getItem("advance"));
let profileData = JSON.parse(secureLocalStorage.getItem("profile"));

const cartCount = cart?.length || 0;
const userAccessToken = userData?.userAccessToken;
const user_id = userData?.user_id;
const earning_point = userData?.earning_point;
const points = userData?.points;
const redeemed_point = userData?.redeemed_point;
const role = userData?.role;
const privileges = userData?.privileges;
const name = userData?.name;

const wishlist_items_count = userData?.wishlist_items_count;
const wishIdz = userData?.wishIdz;

if (!userData) {
  userData = {};
}
if (!highlitedData) {
  highlitedData = {};
}
if (!advanceFilters) {
  advanceFilters = {};
}

const initialState = {
  cartCount: cartCount ? cartCount : 0,
  userAccessToken: userAccessToken ? userAccessToken : null,
  user_id: user_id ? user_id : null,
  earning_point: earning_point ? earning_point : null,
  points: points ? points : null,
  redeemed_point: redeemed_point ? redeemed_point : null,
  role: role ? role : null,
  privileges: privileges ? privileges : null,
  name: name ? name : null,

  wishlist_items_count: wishlist_items_count ? wishlist_items_count : 0,
  wishIdz: wishIdz ? wishIdz : [],
  highlited: highlitedData ? highlitedData : {},
  wishList: wish ? wish : [],
  cartList: cart ? cart : [],
  categoriesList: categories ? categories : [],
  visited: visitedProducts ? visitedProducts : [],
  advance: advanceFilters ? advanceFilters : {},
  info: profileData ? profileData : {},
};

const reducer = (state = initialState, action) => {
  if (action.type === "userLogin") {
    userData.userAccessToken = action.payload.userAccessToken;
    userData.user_id = action.payload.user_id;
    userData.earning_point = action.payload.earning_point;
    userData.redeemed_point = action.payload.redeemed_point;
    userData.points = action.payload.points;
    userData.role = action.payload.role;
    userData.wishlist_items_count = action.payload.wishlist_items_count;
    userData.wishIdz = action.payload.wishIdz;
    userData.cartCount = action.payload.cartCount;
    userData.privileges = action.payload.privileges;
    userData.name = action.payload.name;

    secureLocalStorage.setItem("userInfo", JSON.stringify(userData));
    profileData = action.payload.info;

    secureLocalStorage.setItem("profile", JSON.stringify(profileData));

    return {
      ...state,
      userAccessToken: action.payload.userAccessToken,
      user_id: action.payload.user_id,
      earning_point: action.payload.earning_point,
      redeemed_point: action.payload.redeemed_point,
      points: action.payload.points,
      role: action.payload.role,
      wishlist_items_count: action.payload.wishlist_items_count,
      cartCount: action.payload.cartCount,
      wishIdz: action.payload.wishIdz,
      privileges: action.payload.privileges,
      name: action.payload.name,
      info: action.payload.info,
    };
  } else if (action.type === "userLogOut") {
    secureLocalStorage.removeItem("userInfo");
    secureLocalStorage.removeItem("wishlist");
    secureLocalStorage.removeItem("categoriesList");
    secureLocalStorage.removeItem("highlitedData");
    secureLocalStorage.removeItem("cartlist");
    secureLocalStorage.removeItem("visitedProducts");
    secureLocalStorage.removeItem("advance");
    secureLocalStorage.removeItem("profile");

    return {
      ...state,
      userAccessToken: null,
      user_id: null,
      earning_point: null,
      redeemed_point: null,
      points: null,
      role: null,
      privileges: null,
      name: null,
      wishlist_items_count: 0,
      cartCount: 0,
      wishIdz: [],
      wishList: [],
      highlited: {},
      info: {},
      categoriesList: [],
      cartList: [],
      advance: {},
      visited: [],
    };
  } else if (action.type === "wishCount") {
    userData.wishlist_items_count = action.payload.count;
    userData.wishIdz = action.payload.idz;

    userData.cartCount = cart?.length || 0;

    secureLocalStorage.setItem("userInfo", JSON.stringify(userData));

    return {
      ...state,
      wishlist_items_count: action.payload.count,
      wishIdz: action.payload.idz,
      cartCount: cart?.length || 0,
    };
  } else if (action.type === "cartCounter") {
    userData.cartCount = action.payload;

    secureLocalStorage.setItem("userInfo", JSON.stringify(userData));

    return {
      ...state,
      cartCount: action.payload,
    };
  } else if (action.type === "updateName") {
    userData.name = action.payload;

    secureLocalStorage.setItem("userInfo", JSON.stringify(userData));

    return {
      ...state,
      name: action.payload,
    };
  } else if (action.type === "pointsUpdate") {
    userData.earning_point = action.payload.earning_point;
    userData.redeemed_point = action.payload.redeemed_point;
    userData.points = action.payload.points;

    secureLocalStorage.setItem("userInfo", JSON.stringify(userData));

    return {
      ...state,
      earning_point: action.payload.earning_point,
      redeemed_point: action.payload.redeemed_point,
      points: action.payload.points,
    };
  } else if (action.type === "infoUpdate") {
    profileData = action.payload;

    secureLocalStorage.setItem("profile", JSON.stringify(profileData));

    return {
      ...state,
      info: action.payload,
    };
  } else if (action.type === "highlited") {
    highlitedData = action.payload;

    secureLocalStorage.setItem("highlitedData", JSON.stringify(highlitedData));

    return {
      ...state,
      highlited: action.payload,
    };
  } else if (action.type === "wishListAdd") {
    wish = action.payload;

    secureLocalStorage.setItem("wishlist", JSON.stringify(wish));

    return {
      ...state,
      wishList: action.payload,
    };
  } else if (action.type === "storeCategories") {
    categories = action.payload;

    secureLocalStorage.setItem("categoriesList", JSON.stringify(categories));

    return {
      ...state,
      categoriesList: action.payload,
    };
  } else if (action.type === "cartAdd") {
    cart = action.payload;

    secureLocalStorage.setItem("cartlist", JSON.stringify(cart));

    return {
      ...state,
      cartList: action.payload,
    };
  } else if (action.type === "UPDATE_CART_FROM_STORAGE") {
    console.log(action.payload);

    return {
      ...state,
      cartList: action.payload,
    };
  } else if (action.type === "saveVisit") {
    visitedProducts = action.payload;
    secureLocalStorage.setItem(
      "visitedProducts",
      JSON.stringify(visitedProducts)
    );

    return {
      ...state,
      visited: action.payload,
    };
  } else if (action.type === "advanceFilters") {
    advanceFilters = action.payload;
    secureLocalStorage.setItem("advance", JSON.stringify(advanceFilters));

    return {
      ...state,
      advance: action.payload,
    };
  } else {
    return state;
  }
};
export default reducer;
