import React, {
  useEffect,
  Suspense,
  lazy,
  useState,
  useLayoutEffect,
} from "react";
import { ErrorBoundary } from "react-error-boundary";
import "select2/dist/css/select2.min.css";
import "select2";

import "react-loading-skeleton/dist/skeleton.css";
import "react-datepicker/dist/react-datepicker.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "react-loading-skeleton/dist/skeleton.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "./App.css";

import { Route, Routes, useLocation } from "react-router-dom";

import NotFound from "./NotFound";
import axios from "axios";
import Error429 from "./Error429";

import $ from "jquery";
import { toast, ToastContainer } from "react-toastify";
import Loader from "./Components/Loader";

import { useDispatch, useSelector } from "react-redux";
import { actionCreaters } from "./Redux";
import { bindActionCreators } from "redux";
import MobileMenuFooter from "./Components/User/Includes/MobileMenuFooter";
import moment from "moment";
import UserListService from "./services/user-list.service";
import AdminListService from "./services/admin-list.service";
import CryptoJS from "crypto-js";

const Login = lazy(() => import("./Components/User/Login/Login"));
const ForgotPass = lazy(() => import("./Components/User/Login/ForgotPass"));
const ResetPass = lazy(() => import("./Components/User/Login/ResetPass"));

const FirstPage = lazy(() => import("./Components/User/FirstPage/FirstPage"));
const FirstPageNew = lazy(() =>
  import("./Components/User/FirstPage/FirstPageNew")
);

const ViewRewards = lazy(() =>
  import("./Components/User/ViewRewards/ViewRewards")
);
const AllProducts = lazy(() =>
  import("./Components/User/AllProducts/AllProducts")
);
const ViewProducts = lazy(() =>
  import("./Components/User/AllProducts/ViewProducts")
);

const ProductDetail = lazy(() =>
  import("./Components/User/ProductDetail/ProductDetail")
);

const OrderHistory = lazy(() => import("./Components/User/Order/OrderHistory"));
const UserOrderDetails = lazy(() =>
  import("./Components/User/Order/UserOrderDetails")
);

const RefundOrderHistory = lazy(() =>
  import("./Components/User/Order/RefundOrderHistory")
);
const ThankYou = lazy(() => import("./Components/User/Order/ThankYou"));

const WishList = lazy(() => import("./Components/User/Order/WishList"));
const WishListNew = lazy(() => import("./Components/User/Order/WishListNew"));

const Cart = lazy(() => import("./Components/User/Order/Cart"));
const CartNew = lazy(() => import("./Components/User/Order/CartNew"));

const Profile = lazy(() => import("./Components/User/Profile/Profile"));
const Contact = lazy(() => import("./Components/User/Contact/Contact"));
const Help = lazy(() => import("./Components/User/Contact/Help"));

const SearchedProducts = lazy(() =>
  import("./Components/User/Search/SearchedProducts")
);
const AdvanceSearchedProducts = lazy(() =>
  import("./Components/User/Search/AdvanceSearchedProducts")
);

// admin component down
// const AddBadges = lazy(() => import("./Components/Admin/Badges/AddBadges"));
// const EditBadges = lazy(() => import("./Components/Admin/Badges/EditBadges"));
// const ManageBadges = lazy(() =>
//   import("./Components/Admin/Badges/ManageBadges")
// );

const ManageOrders = lazy(() =>
  import("./Components/Admin/ManageOrders/ManageOrders")
);
const RefundedOrders = lazy(() =>
  import("./Components/Admin/ManageOrders/RefundedOrders")
);
const AddParticipant = lazy(() =>
  import("./Components/Admin/Participant/AddParticipant")
);
const EditParticipant = lazy(() =>
  import("./Components/Admin/Participant/EditParticipant")
);

const ViewParticipant = lazy(() =>
  import("./Components/Admin/Participant/ViewParticipant")
);
const ViewAllParticipant = lazy(() =>
  import("./Components/Admin/Participant/ViewAllParticipant")
);
const ParticipantInfo = lazy(() =>
  import("./Components/Admin/Participant/ParticipantInfo")
);

const ParticipantWishlist = lazy(() =>
  import("./Components/Admin/Participant/ParticipantWishlist")
);
const WishListParticipants = lazy(() =>
  import("./Components/Admin/Participant/Wishlist/WishListParticipants")
);
const AddAdminUser = lazy(() =>
  import("./Components/Admin/ManageUser/AdminUser/AddAdminUser")
);
const EditAdminUser = lazy(() =>
  import("./Components/Admin/ManageUser/AdminUser/EditAdminUser")
);
const ViewAdminUser = lazy(() =>
  import("./Components/Admin/ManageUser/AdminUser/ViewAdminUser")
);
const AddVipUser = lazy(() =>
  import("./Components/Admin/ManageUser/VipUser/AddVipUser")
);
const EditVipUser = lazy(() =>
  import("./Components/Admin/ManageUser/VipUser/EditVipUser")
);

const ViewVipUser = lazy(() =>
  import("./Components/Admin/ManageUser/VipUser/ViewVipUser")
);
const AdminPoints = lazy(() =>
  import("./Components/Admin/ManagePoints/AdminPoints")
);
const SitePoints = lazy(() =>
  import("./Components/Admin/ManagePoints/SitePoints")
);
const PointsManagement = lazy(() =>
  import("./Components/Admin/ManagePoints/PointsManagement")
);
const AdjustPoints = lazy(() =>
  import("./Components/Admin/ManagePoints/AdjustPoints")
);
const ManageVipPointsHistory = lazy(() =>
  import("./Components/Admin/ManageHistory/ManageVipPointsHistory")
);
const ManageAdminUserPointsHistory = lazy(() =>
  import("./Components/Admin/ManageHistory/ManageAdminUserPointsHistory")
);

const Reports = lazy(() => import("./Components/Admin/Reports/Reports"));
const EarnedReport = lazy(() =>
  import("./Components/Admin/Reports/EarnedReport")
);
const EarnedDetailReport = lazy(() =>
  import("./Components/Admin/Reports/EarnedDetailReport")
);

const EnrollmentReport = lazy(() =>
  import("./Components/Admin/Reports/EnrollmentReport")
);
const RedemptionReport = lazy(() =>
  import("./Components/Admin/Reports/RedemptionReport")
);
const ImportUsers = lazy(() =>
  import("./Components/Admin/ManageDocument/ImportUsers")
);

const PagesHits = lazy(() => import("./Components/Admin/Settings/PagesHits"));
const LoginInformation = lazy(() =>
  import("./Components/Admin/Settings/LoginInformation")
);

const SiteInfo = lazy(() => import("./Components/Admin/Settings/SiteInfo"));

const HelpContent = lazy(() =>
  import("./Components/Admin/ManageContent/HelpContent")
);

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>
            4<span>2</span>4
          </h1>
        </div>
        <h2>{error.message}</h2>
        <button
          className="btn btn-block btn-primary nm-btn"
          onClick={resetErrorBoundary}
        >
          Try again
        </button>
      </div>
    </div>
  );
}
export default function Roots() {
  const locat = useLocation();
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  const { userAccessToken, user_id } = useSelector((state) => state.stateVals);

  const [isVisible, setIsVisible] = useState(false);

  // Show or hide the button based on scroll position
  const toggleVisibility = () => {
    if (window.scrollY > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const backToTop = () => {
    return (
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      ),
      !1
    );
  };

  const getFormattedDuration = async (startTime) => {
    try {
      const exitTime = await moment();
      const duration = await moment.duration(exitTime?.diff(startTime));
      const years = duration.years();
      const months = duration.months();
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      const formatted = [];
      if (years > 0) formatted.push(`${years} year${years > 1 ? "s" : ""}`);
      if (months > 0) formatted.push(`${months} month${months > 1 ? "s" : ""}`);
      if (days > 0) formatted.push(`${days} day${days > 1 ? "s" : ""}`);
      if (hours > 0) formatted.push(`${hours} hour${hours > 1 ? "s" : ""}`);
      if (minutes > 0)
        formatted.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
      if (seconds > 0)
        formatted.push(`${seconds} second${seconds > 1 ? "s" : ""}`);

      if (formatted?.length && userAccessToken) {
        const response = await UserListService.pageHits(
          userAccessToken,
          user_id,
          formatted.join(" "),
          locat.pathname
        );
        userActions.logIn(
          {
            earning_point: response?.data?.data?.user?.earning_point,
            points: response?.data?.data?.user?.points,
            redeemed_point: response?.data?.data?.user?.redeemed_point,
          },
          "pointsUpdate"
        );
      }
    } catch (error) {}
  };
  useEffect(() => {
    backToTop();
    const entryTime = moment();

    if (locat.pathname.match(/\/{2,}/)) {
      if (locat.pathname.indexOf("admin/") > -1) {
        window.location.href = "/admin/login";
      } else {
        window.location.href = "/";
      }
    }

    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 429) {
          window.location.href = "/error-429";
        } else if (error?.response?.status === 401 && locat.pathname !== "/") {
          toast.error("Unauthorized!", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            onClose: () => {
              window.location.href = "/";
            },
          });
          userActions.logOut("userLogOut");
        }

        return Promise.reject(error);
      }
    );

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      if (userAccessToken && user_id) {
        getFormattedDuration(entryTime);
      }
      axios.interceptors.response.eject(interceptor);
      window.removeEventListener("scroll", toggleVisibility);
    };
    // eslint-disable-next-line
  }, [locat.pathname]);
  const setColors = async () => {
    try {
      const response = await AdminListService.getSiteInfoDetails(
        userAccessToken
      );

      await document.documentElement.style.setProperty(
        "--primary-color",
        response?.data?.response?.web_info?.primary_color
      );
      await document.documentElement.style.setProperty(
        "--secondary-color",
        response?.data?.response?.web_info?.secondary_color
      );
    } catch (error) {}
  };
  useLayoutEffect(() => {
    userActions.addCategories([], "storeCategories");
    userActions.highlitedProduct({}, "highlited");
    userActions.addCategories([], "saveVisit");
    userActions.addCategories([], "advanceFilters");

    setColors();
    const handleStorageChange = (event) => {
      const secretKey = "redux-central-system-of-otb";
      const decryptData = (encryptedData) => {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedString); // Convert string back to object/array
      };

      if (event.key === "cartlist" && event?.newValue) {
        const decryptedString = decryptData(event?.newValue);

        userActions.addToCart(decryptedString, "cartUpdate");
      }

      if (event.key === "userInfo") {
        if (event?.newValue) {
          const decryptedString = decryptData(event?.newValue);

          userActions.logIn(decryptedString, "userUpdateFromStorage");
        } else {
          userActions.logIn({}, "userUpdateFromStorage");
          setTimeout(() => {
            window.location.href = "/";
          }, 200);
        }
      }

      if (event.key === "profile" && event?.newValue) {
        const decryptedString = decryptData(event?.newValue);

        userActions.cartCounter(decryptedString, "infoUpdateFromStorage");
      }
    };

    // Add the storage event listener
    window.addEventListener("storage", handleStorageChange);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      <ToastContainer />
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
        onError={(error, info) => {
          // Log error or send it to a tracking service
          console.error("Caught an error:", error, info);
        }}
      >
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Login />} caseSensitive={true} />
            <Route
              path="/forgot-password"
              element={<ForgotPass />}
              caseSensitive={true}
            />
            <Route
              path="/account/reset-password/:token"
              element={<ResetPass />}
              caseSensitive={true}
            />
            <Route
              path="/first-page"
              element={<FirstPageNew />}
              caseSensitive={true}
            />
            <Route
              path="/first-page-old"
              element={<FirstPage />}
              caseSensitive={true}
            />
            <Route
              path="/view-reward"
              element={<ViewRewards />}
              caseSensitive={true}
            />
            <Route
              path="/all-products/:type"
              element={<AllProducts />}
              caseSensitive={true}
            />
            <Route
              path="/all-products/:type/:category"
              element={<AllProducts />}
              caseSensitive={true}
            />
            <Route
              path="/view-products/:category"
              element={<ViewProducts />}
              caseSensitive={true}
            />
            <Route
              path="/view-products/:category/:subcategory"
              element={<ViewProducts />}
              caseSensitive={true}
            />
            <Route
              path="/product-detail/:id"
              element={<ProductDetail />}
              caseSensitive={true}
            />
            <Route
              path="/thank-you/:order_id"
              element={<ThankYou />}
              caseSensitive={true}
            />
            <Route
              path="/order-history"
              element={<OrderHistory />}
              caseSensitive={true}
            />
            <Route
              path="/user-order-details/:order_id"
              element={<UserOrderDetails />}
              caseSensitive={true}
            />
            <Route
              path="/refund-order-history"
              element={<RefundOrderHistory />}
              caseSensitive={true}
            />
            <Route
              path="/wishlist"
              element={<WishListNew />}
              caseSensitive={true}
            />
            <Route
              path="/wishlist-new"
              element={<WishList />}
              caseSensitive={true}
            />
            <Route path="/cart" element={<CartNew />} caseSensitive={true} />
            <Route path="/cart-new" element={<Cart />} caseSensitive={true} />
            <Route path="/help" element={<Help />} caseSensitive={true} />
            <Route
              path="/contact-us"
              element={<Contact />}
              caseSensitive={true}
            />
            <Route
              path="/my-account"
              element={<Profile />}
              caseSensitive={true}
            />
            <Route
              path="/search-product"
              element={<SearchedProducts />}
              caseSensitive={true}
            />
            <Route
              path="/advance-search"
              element={<AdvanceSearchedProducts />}
              caseSensitive={true}
            />
            {/* admin routes  */}
            {/* <Route
            path="/add-badge"
            element={<AddBadges />}
            caseSensitive={true}
          />
          <Route
            path="/edit-badges/:id"
            element={<EditBadges />}
            caseSensitive={true}
          />
          <Route
            path="/view-badges"
            element={<ManageBadges />}
            caseSensitive={true}
          /> */}
            <Route
              path="/view-orders"
              element={<ManageOrders />}
              caseSensitive={true}
            />
            <Route
              path="/refunded-orders"
              element={<RefundedOrders />}
              caseSensitive={true}
            />
            <Route
              path="/order-details/:type/:id"
              element={<ManageOrders />}
              caseSensitive={true}
            />
            <Route
              path="/add-participant"
              element={<AddParticipant />}
              caseSensitive={true}
            />
            <Route
              path="/edit-participant/:id"
              element={<EditParticipant />}
              caseSensitive={true}
            />
            <Route
              path="/view-participants"
              element={<ViewParticipant />}
              caseSensitive={true}
            />
            <Route
              path="/view-all-participants"
              element={<ViewAllParticipant />}
              caseSensitive={true}
            />
            <Route
              path="/detail-info/:id"
              element={<ParticipantInfo />}
              caseSensitive={true}
            />
            <Route
              path="/participants-wishlist"
              element={<ParticipantWishlist />}
              caseSensitive={true}
            />
            <Route
              path="/participants-wishlist/:id"
              element={<WishListParticipants />}
              caseSensitive={true}
            />
            <Route
              path="/add-admin-user"
              element={<AddAdminUser />}
              caseSensitive={true}
            />
            <Route
              path="/edit-admin-user/:id"
              element={<EditAdminUser />}
              caseSensitive={true}
            />
            <Route
              path="/view-admin-users"
              element={<ViewAdminUser />}
              caseSensitive={true}
            />
            <Route
              path="/add-vip-user"
              element={<AddVipUser />}
              caseSensitive={true}
            />
            <Route
              path="/edit-vip-user/:id"
              element={<EditVipUser />}
              caseSensitive={true}
            />
            <Route
              path="/view-vip-users"
              element={<ViewVipUser />}
              caseSensitive={true}
            />
            <Route
              path="/admin-points"
              element={<AdminPoints />}
              caseSensitive={true}
            />
            <Route
              path="/site-points"
              element={<SitePoints />}
              caseSensitive={true}
            />
            <Route
              path="/points-management"
              element={<PointsManagement />}
              caseSensitive={true}
            />
            <Route
              path="/adjust-points/:id"
              element={<AdjustPoints />}
              caseSensitive={true}
            />
            <Route
              path="/vip-points-history"
              element={<ManageVipPointsHistory />}
              caseSensitive={true}
            />
            <Route
              path="/admin-users-points-history"
              element={<ManageAdminUserPointsHistory />}
              caseSensitive={true}
            />
            <Route
              path="/view-reports"
              element={<Reports />}
              caseSensitive={true}
            />
            <Route
              path="/earned-report"
              element={<EarnedReport />}
              caseSensitive={true}
            />
            <Route
              path="/earning-detail-report"
              element={<EarnedDetailReport />}
              caseSensitive={true}
            />
            <Route
              path="/enrollment-report"
              element={<EnrollmentReport />}
              caseSensitive={true}
            />
            <Route
              path="/redemption-detail-report"
              element={<RedemptionReport />}
              caseSensitive={true}
            />
            <Route
              path="/import-users"
              element={<ImportUsers />}
              caseSensitive={true}
            />
            <Route
              path="/page-hits"
              element={<PagesHits />}
              caseSensitive={true}
            />
            <Route
              path="/page-hits/:id"
              element={<PagesHits />}
              caseSensitive={true}
            />
            <Route
              path="/login-infos"
              element={<LoginInformation />}
              caseSensitive={true}
            />
            <Route
              path="/site-info"
              element={<SiteInfo />}
              caseSensitive={true}
            />
            <Route
              path="/manage-help"
              element={<HelpContent />}
              caseSensitive={true}
            />

            <Route
              path="/error-429"
              element={<Error429 />}
              caseSensitive={true}
            />
            {/* will remove it  */}
            <Route
              path="/view-all-participans"
              element={<ViewAllParticipant />}
              caseSensitive={true}
            />
            <Route
              path="/view-vip-user"
              element={<ViewVipUser />}
              caseSensitive={true}
            />
            <Route
              path="/view-admin-user"
              element={<ViewAdminUser />}
              caseSensitive={true}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
      {locat.pathname !== "/forgot-password" &&
      locat.pathname !== "/" &&
      !locat.pathname.startsWith("/account/reset-password/") &&
      userAccessToken ? (
        <MobileMenuFooter />
      ) : null}
      {locat.pathname.match(/\/{2,}/) ? (
        <div className="white-screen"></div>
      ) : null}

      {isVisible && (
        <button onClick={backToTop} className="to-top-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-up"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"
            />
          </svg>
        </button>
      )}
    </>
  );
}
