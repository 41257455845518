import axios from "axios";

const API_URL = process.env.REACT_APP_API_Link;

const putBadge = (token, values) => {
  return axios.post(API_URL + "badges/store", values, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

const listBadges = (token, limit, page) => {
  return axios.get(
    `${API_URL}badges/index?limit=${limit}&page=${page}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const deleteBadgeById = (token, id, status) => {
  return axios.put(
    `${API_URL}badges/actions`,
    {
      id: id,
      status: status,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getBadgeById = (token, id) => {
  return axios.get(
    `${API_URL}badges/show/${id}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const updateBadgeById = (token, values, id) => {
  return axios.post(API_URL + "badges/update/" + id, values, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

const listOrders = (token, limit, page, status, search) => {
  return axios.get(
    `${API_URL}admin/orders/index?limit=${limit}&page=${page}&status=${status}&search_column=${
      search?.searchColumn || ""
    }&search_term=${search?.searchString || ""}&start_date=${
      search?.start || ""
    }&end_date=${search?.end || ""}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const orderById = (token, id) => {
  return axios.get(
    `${API_URL}admin/orders/list`,

    {
      params: {
        order_id: id,
        type: "active",
        offset: 0,
        limit: 99999999,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const refundOrderById = (token, id) => {
  return axios.get(
    `${API_URL}admin/orders/list-refunded-order`,

    {
      params: {
        order_id: id,
        offset: 0,
        limit: 99999999,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const deleteByIdOrder = (token, id) => {
  return axios.delete(
    `${API_URL}admin/orders/delete/${id}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const deleteAllOrder = (token) => {
  return axios.delete(
    `${API_URL}admin/orders/delete-all`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const deleteProductFromOrder = (token, id) => {
  return axios.delete(
    `${API_URL}admin/orders/delete-item/${id}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getOrderEport = (token, status, search) => {
  return axios.get(
    `${API_URL}admin/orders/export-orders?type=${status}&searchColumn=${
      search?.searchColumn || ""
    }&searchString=${search?.searchString || ""}&startDate=${
      search?.start || ""
    }&endDate=${search?.end || ""}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getRefundedOrderEport = (token, search) => {
  return axios.get(
    `${API_URL}admin/orders/export-refunded-order?searchColumn=${
      search?.searchColumn || ""
    }&searchString=${search?.searchString || ""}&startDate=${
      search?.start || ""
    }&endDate=${search?.end || ""}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const addEmploye = (token, values) => {
  return axios.post(API_URL + "admin/employee/add", values, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
const listEmployees = (
  token,
  limit,
  page,
  status,
  search,
  type,
  columnType = ""
) => {
  return axios.post(
    `${API_URL}admin/employee/list`,
    {
      limit: limit,
      page: page,
      type: type,
      queryData: {
        sort: search?.sort,
        status: status,
        searchColumn: search?.searchColumn,
        searchString: search?.searchString,
      },
      search_columns: columnType,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getEmployPassword = (token, id) => {
  return axios.get(
    `${API_URL}admin/employee/show-password/${id}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const getEmployWxport = (token, status) => {
  return axios.post(
    `${API_URL}admin/employee/export`,
    {
      queryData: {
        status: status,
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const deleteAllEmploy = (token) => {
  return axios.delete(
    `${API_URL}admin/employee/delete-all`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const deleteByIdEmploy = (token, id) => {
  return axios.delete(
    `${API_URL}admin/employee/delete/${id}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const sendCredentialsToEmploy = (token, id) => {
  return axios.post(
    `${API_URL}auth/send-credentials`,
    {
      userid: id,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const changeEmployRedemptionStatus = (token, id, status) => {
  return axios.put(
    `${API_URL}admin/employee/update-status/${id}`,
    {
      status: status,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const changeEmployNewsletterStatus = (token, id, status) => {
  return axios.put(
    `${API_URL}admin/employee/subscribe-status/${id}`,
    {
      status: status,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getEmployById = (token, id) => {
  return axios.get(
    `${API_URL}admin/user-detail/${id}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const updateEmployeById = (token, values, id) => {
  return axios.put(`${API_URL}admin/employee/update/${id}`, values, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

const listEmployeDataById = (token, limit, page, type, id, sdate, edate) => {
  return axios.get(
    `${API_URL}admin/user/detail?id=${id}&limit=${limit}&type=${type}&offset=${page}&start_date=${sdate}&end_date=${edate}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const listEmployeesWishlist = (token, limit, page) => {
  return axios.get(
    `${API_URL}admin/admin-wishlist-users?limit=${limit}&page=${page}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const addAdminUser = (token, values) => {
  return axios.post(
    API_URL + "admin/user/add",
    {
      password: values.password,
      username: values.username,
      fname: values.fname,
      lname: values.lname,
      email: values.email,
      points: values.points,
      user_privilege: {
        manage_settings: values.manage_settings ? "yes" : "no",
        manage_reports: values.manage_reports ? "yes" : "no",
        manage_points: values.manage_points ? "yes" : "no",
        manage_history: values.manage_history ? "yes" : "no",
        manage_content: values.manage_content ? "yes" : "no",
        manage_participant: values.manage_participant ? "yes" : "no",
        manage_document: values.manage_documents ? "yes" : "no",
        manage_user: values.manage_users ? "yes" : "no",
        manage_orders: values.manage_orders ? "yes" : "no",
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const adminGetById = (token, id) => {
  return axios.get(
    `${API_URL}admin/user/get-by-id`,

    {
      params: {
        id: id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const updateAdminUser = (token, values, id) => {
  return axios.put(
    `${API_URL}admin/user/update/${id}`,
    {
      password: values.password,
      username: values.username,
      fname: values.fname,
      lname: values.lname,
      email: values.email,
      points: values.points,
      user_privileges: {
        manage_settings: values.manage_settings ? "yes" : "no",
        manage_reports: values.manage_reports ? "yes" : "no",
        manage_points: values.manage_points ? "yes" : "no",
        manage_history: values.manage_history ? "yes" : "no",
        manage_content: values.manage_content ? "yes" : "no",
        manage_participant: values.manage_participants ? "yes" : "no",
        manage_document: values.manage_documents ? "yes" : "no",
        manage_user: values.manage_users ? "yes" : "no",
        manage_orders: values.manage_orders ? "yes" : "no",
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const listAdminUser = (token, limit, page, status, search) => {
  return axios.post(
    API_URL + "admin/user/list",
    {
      limit: limit,
      page: page,
      queryData: {
        sort: search?.sort,
        status: status,
        searchColumn: search?.searchColumn,
        searchString: search?.searchString,
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const deleteAdminById = (token, id) => {
  return axios.delete(
    `${API_URL}admin/user/delete/${id}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const addVipUser = (token, values) => {
  return axios.post(
    API_URL + "admin/vip/add",
    {
      password: values.password,
      username: values.username,
      fname: values.fname,
      lname: values.lname,
      email: values.email,
      points: values.points,

      user_privilege: {
        manage_settings: values.manage_settings ? "yes" : "no",
        manage_reports: values.manage_reports ? "yes" : "no",
        manage_points: values.manage_points ? "yes" : "no",
        // manage_history: values.manage_history ? "yes" : "no",
        manage_content: values.manage_content ? "yes" : "no",
        manage_participant: values.manage_participants ? "yes" : "no",
        manage_document: values.manage_documents ? "yes" : "no",

        manage_orders: values.manage_orders ? "yes" : "no",
      },
    },

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const listVipUser = (token, limit, page, status, search) => {
  return axios.post(
    API_URL + "admin/vip/list",
    {
      limit: limit,
      page: page,
      queryData: {
        sort: search?.sort,
        status: status,
        searchColumn: search?.searchColumn,
        searchString: search?.searchString,
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const deleteAllVips = (token) => {
  return axios.get(
    `${API_URL}admin/vip/delete-all`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const deleteVipById = (token, id) => {
  return axios.delete(
    `${API_URL}admin/vip/delete/${id}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const vpGetById = (token, id) => {
  return axios.get(
    `${API_URL}admin/vip/get-by-id`,

    {
      params: {
        id: id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const updatedVipUser = (token, values, id) => {
  return axios.put(
    `${API_URL}admin/vip/update/${id}`,
    {
      password: values.password,
      username: values.username,
      first_name: values.fname,
      last_name: values.lname,
      email: values.email,
      points: values.points,

      user_privilege: {
        manage_settings: values.manage_settings ? "yes" : "no",
        manage_reports: values.manage_reports ? "yes" : "no",
        manage_points: values.manage_points ? "yes" : "no",
        // manage_history: values.manage_history ? "yes" : "no",
        manage_content: values.manage_content ? "yes" : "no",
        manage_participant: values.manage_participants ? "yes" : "no",
        manage_document: values.manage_documents ? "yes" : "no",

        manage_orders: values.manage_orders ? "yes" : "no",
      },
    },

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const listAllUserPoints = (token, limit, page, search) => {
  return axios.get(
    `${API_URL}admin/earning-detail?limit=${limit}&offset=${page}&type=${search?.type}&searchColumn=${search?.column}&searchString=${search?.search}&status=${search?.status}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const listAllSitePoints = (token, start, end) => {
  return axios.get(
    `${API_URL}admin/points-info?start_date=${start}&end_date=${end}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const adminPointGet = (token, id) => {
  return axios.get(
    `${API_URL}admin/user/get-point-by-id/${id}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const adminPointUpdate = (token, values) => {
  return axios.put(
    `${API_URL}admin/user/update-points`,
    {
      points: values.points,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const pointsAdjustments = (token, values, id) => {
  return axios.put(
    `${API_URL}admin/adjust-points/${id}`,
    {
      points: values.points,
      action: values.action,
      note: values.notes,
      emailSend: values.emailSend,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const listPointsHistory = (token, limit, page, type, search) => {
  return axios.get(
    `${API_URL}admin/earning-detail?limit=${limit}&offset=${page}&type=${type}&searchColumn=${
      search?.searchColumn
    }&searchString=${search?.searchString}&startDate=${
      search?.start || ""
    }&endDate=${search?.end || ""}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const exportAllEnrolledParticipantsInformation = (
  token,
  limit = "All",
  page = 0,
  status = "all",
  search = {},
  type = "participant",
  columnType = ""
) => {
  return axios.post(
    `${API_URL}admin/enrollment-report`,
    {
      limit: limit,
      offset: page,
      type: type,
      queryData: {
        sort: search?.sort,
        status: status,
        searchColumn: search?.searchColumn,
        searchString: search?.searchString,
      },
      search_columns: columnType,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const listEarnedReportData = (token, limit, page, search, type) => {
  return axios.get(`${API_URL}admin/earned-report`, {
    params: {
      limit: limit,
      offset: page,
      type: type,

      searchColumn: search?.column,
      searchString: search?.search,
      startDate: search?.start,
      endDate: search?.end,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

const listEarnedDetailReportData = (token, limit, page, search, type) => {
  return axios.get(`${API_URL}admin/earning-detail`, {
    params: {
      limit: limit,
      offset: page,
      type: type,

      searchColumn: search?.column,
      searchString: search?.search,
      startDate: search?.start,
      endDate: search?.end,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

const listEnrollmentDetailReportData = (token, limit, page, search, type) => {
  return axios.post(
    `${API_URL}admin/enrollment-report`,
    {
      limit: limit,
      offset: page,
      type: type,
      startDate: search?.start,
      endDate: search?.end,
      queryData: {
        searchColumn: search?.column,
        searchString: search?.search,
        status: "all",
        sort: "created_at desc",
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const listRedemptionReportData = (token, limit, page, search, type) => {
  return axios.get(`${API_URL}admin/redemtion-report`, {
    params: {
      limit: limit,
      offset: page,
      type: type,

      searchColumn: search?.column,
      searchString: search?.search,
      startDate: search?.start,
      endDate: search?.end,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

const importUsers = (token, values) => {
  return axios.post(API_URL + "admin/import-users", values, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

const mostVisitedPages = (token, limit, page, id) => {
  return axios.get(
    `${API_URL}admin/list-page-hits?limit=${limit}&offset=${page}&user_id=${id}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const loginInformations = (token, limit, page) => {
  return axios.get(
    `${API_URL}admin/login-info?limit=${limit}&offset=${page}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getSiteInfoDetails = () => {
  return axios.get(
    `${API_URL}info/site-info-details`,

    {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    }
  );
};

const updateWebInfo = (token, values, id) => {
  return axios.get(
    `${API_URL}info/update-site-info`,

    {
      params: { ...values, website_url: window?.location?.hostname },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const updateSiteCatalog = (token, values) => {
  return axios.post(
    `${API_URL}info/update-site-config`,

    {
      markup: {
        bestbuy_markup: values.bestbuy_markup,
        gift_cards_markup: values.gift_cards_markup,
        master_cards_markup: values.master_cards_markup,
        merchandise_markup: values.merchandise_markup,
        vacations_markup: values.vacations_markup,
        virtual_cards_markup: values.virtual_cards_markup,
      },
      config: {
        bestbuy: values.bestbuy,
        merchandise: values.merchandise,
        gift_cards: values.gift_cards,
        master_cards: values.master_cards,
        vacations: values.vacations,
        virtual_cards: values.virtual_cards,
      },
      site: window?.location?.hostname,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const helpContent = (token, module) => {
  return axios.get(
    API_URL + `admin/content/get?module=${module}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const AdminListService = {
  putBadge,
  listBadges,
  deleteBadgeById,
  getBadgeById,
  updateBadgeById,
  listOrders,
  orderById,
  refundOrderById,
  deleteByIdOrder,
  deleteAllOrder,
  deleteProductFromOrder,
  getOrderEport,
  getRefundedOrderEport,
  addEmploye,
  listEmployees,
  getEmployPassword,
  getEmployWxport,
  changeEmployRedemptionStatus,
  changeEmployNewsletterStatus,
  deleteAllEmploy,
  deleteByIdEmploy,
  sendCredentialsToEmploy,
  getEmployById,
  updateEmployeById,
  listEmployeDataById,
  listEmployeesWishlist,
  addAdminUser,
  updateAdminUser,
  adminGetById,
  listAdminUser,
  deleteAdminById,
  addVipUser,
  listVipUser,
  deleteAllVips,
  deleteVipById,
  vpGetById,
  updatedVipUser,
  listAllUserPoints,
  listAllSitePoints,
  adminPointUpdate,
  adminPointGet,
  pointsAdjustments,
  listPointsHistory,
  exportAllEnrolledParticipantsInformation,
  listEarnedReportData,
  listEarnedDetailReportData,
  listEnrollmentDetailReportData,
  listRedemptionReportData,
  importUsers,
  mostVisitedPages,
  loginInformations,
  getSiteInfoDetails,
  updateWebInfo,
  updateSiteCatalog,
  helpContent,
};

export default AdminListService;
