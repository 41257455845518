import React from "react";

export default function NotFound() {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>
            4<span>0</span>4
          </h1>
        </div>
        <h2>This page is currently unavailable or may have been removed.</h2>
        <a href="/" className="btn btn-block btn-primary nm-btn">
          Back To Homepage
        </a>
      </div>
    </div>
  );
}
