import axios from "axios";

const API_URL = process.env.REACT_APP_API_Link;
const API_URL_Central = process.env.REACT_APP_Central_API_Link;

const headers2 = {
  "Content-Type": "application/json",
};
const pageHits = (token, user_id, visit_duration, visit_page) => {
  return axios.post(
    API_URL + "admin/log-page-hits",
    {
      visit_page: `${window.location.origin}${visit_page}`,
      visit_duration: visit_duration,
      user_id: user_id,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const loginUser = (values) => {
  return axios.post(
    API_URL + "auth/login",
    {
      username: values.username,
      password: values.password,
    },
    {
      headers: headers2,
    }
  );
};

const forgotPassUser = (values) => {
  return axios.post(
    API_URL + "auth/recover",
    {
      email: values.email,
      type: values.type,
    },
    {
      headers: headers2,
    }
  );
};

const resetToekenVerify = (token) => {
  return axios.post(
    API_URL + "auth/verify-reset-token",
    {
      token: token,
    },
    {
      headers: headers2,
    }
  );
};
const resetUserPassword = (pass, token) => {
  return axios.post(
    API_URL + "auth/update-password",
    {
      password: pass,
      token: token,
    },
    {
      headers: headers2,
    }
  );
};

const getHighlitedProducts = (token) => {
  return axios.post(
    API_URL_Central + "products/highlighted",
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const getRelatedProducts = (id, subcategory_id, brand, token) => {
  return axios.get(
    API_URL_Central +
      "products/get-related-products?id=" +
      id +
      "&subcategory_id=" +
      subcategory_id +
      "&brand=" +
      brand,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getProductsCatagory = (token) => {
  return axios.get(
    API_URL_Central + "category/listing",

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const contentGetMainSlider = (page, status, token) => {
  return axios.get(
    API_URL + "banners/index?page=" + page + "&status=" + status,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const getProductsById = (id, token) => {
  return axios.get(
    API_URL_Central + "products/detail?id=" + id,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const addInWish = (values) => {
  return axios.post(
    API_URL + "admin/add-wishlist",
    {
      product_id: values.product_id,
      product_number: values.product_number,
      cat_type: values.cat_type,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${values.userAccessToken}`,
      },
    }
  );
};

const listWish = (token, id = "") => {
  return axios.post(
    `${API_URL}admin/wishlist-list`,
    { user_id: id },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const listOrdered = (token, limit, page, searchTerm, include_trashed = "") => {
  return axios.get(
    `${API_URL}order/history/list?limit=${limit}&page=${page}&type=${include_trashed}${
      searchTerm !== "" ? `&search_term=${searchTerm}` : ""
    }`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// const wishCount = (token) => {
//   return axios.post(
//     API_URL + "admin/wishlist-counter",
//     {},
//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
// };
// const wishAddedIdz = (token) => {
//   return axios.post(
//     API_URL + "admin/wishlist-ids",
//     {},
//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
// };

const emptyWishList = (token) => {
  return axios.delete(
    API_URL + "admin/delete-all-wishlist",

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const deleteById = (token, id) => {
  return axios.delete(
    API_URL + "admin/delete-wishlist/" + id,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// const addInCart = (values) => {
//   return axios.post(
//     API_URL + "admin/add-cart",
//     {
//       product_id: values.product_id,
//       product_number: values.product_number,
//       cat_type: values.cat_type,
//       product_image: values.product_image,
//       product_model: values.product_model,
//       product_name: values.product_name,
//       product_points: values.product_points,
//       product_type: values.product_type,
//       quantity: values.quantity ? values.quantity : 1,
//     },
//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${values.userAccessToken}`,
//       },
//     }
//   );
// };

// const quantityDecrease = (values) => {
//   return axios.post(
//     API_URL + "admin/decrease-cart-quantity",
//     {
//       product_id: values.product_id,
//       product_number: values.product_number,
//       cat_type: values.cat_type,
//     },
//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${values.userAccessToken}`,
//       },
//     }
//   );
// };

// const getCartCounter = (token) => {
//   return axios.post(
//     API_URL + "admin/cart-counter",
//     {},
//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
// };

// const listCart = (token) => {
//   return axios.post(
//     API_URL + "admin/cart-list",
//     {},
//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
// };

// const emptyCartList = (token) => {
//   return axios.delete(
//     API_URL + "admin/delete-all-cart",

//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
// };

// const deleteCartById = (token, id) => {
//   return axios.delete(
//     API_URL + "admin/delete-cart/" + id,

//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
// };

const getProfile = (token, type) => {
  return axios.post(
    API_URL + "auth/profile-by-type/" + type,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const updateProfileByType = (token, type, values) => {
  return axios.post(
    API_URL + "auth/update-profile-by-type/" + type,
    { ...values },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const updateProfileImage = (token, type, image) => {
  return axios.post(
    API_URL + "auth/update-profile-by-type/" + type,
    { image: image },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getAllStates = (token) => {
  return axios.post(
    API_URL + "admin/states-list",
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const citiesByStateId = (token, id) => {
  return axios.post(
    API_URL + "admin/city-by-state/" + id,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const makeContact = (values, token) => {
  return axios.post(
    API_URL + "info/contact-us",
    { ...values },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const listProductByType = (token, type, page, category, sortBy, orderBy) => {
  return axios.get(
    `${API_URL_Central}products/by-tag?page=${page}&limit=25&tag=${type}&sortBy=${sortBy}&orderBy=${orderBy}${
      category ? `&category=${category}` : ""
    }`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const listProductByCategory = (
  token,
  page,
  category,
  subcategory,
  sortBy,
  orderBy
) => {
  return axios.get(
    `${API_URL_Central}products/index?page=${page}&category=${category}&sortBy=${sortBy}&orderBy=${orderBy}${
      subcategory ? `&sub_category=${subcategory}` : ""
    }`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const listheaderCategories = (token) => {
  return axios.get(
    `${API_URL_Central}category/index`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const searchInHeader = (token, search, page, sortBy, orderBy) => {
  return axios.get(
    `${API_URL_Central}products/advance-search?offset=${page}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const searchInAdvance = (
  token,
  search,
  page,
  limit,
  selectedFilters,
  values
) => {
  return axios.get(
    `${API_URL_Central}products/advance-search`,

    {
      params: {
        offset: page,
        limit: limit,
        search: search,
        selected_filters: selectedFilters,
        points_filter: values,
        search: values?.search || "",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const checkEligibillity = (token, cart) => {
  return axios.post(
    `${API_URL}order/check-eligibility`,
    {
      cart_total: cart?.reduce((a, v) => (a = a + v.total), 0),
      cart: cart,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const placeOrder = (token, values, cartList) => {
  return axios.post(
    `${API_URL}order/store`,
    {
      address1: values.address1,
      city: values.city,
      address2: values.address2,
      email: values.email,
      first_name: values.fname,
      last_name: values.lname,
      mobile: values.mobile,
      postal_code: values.zip,
      phone: values.phone,
      state_code: values.state_id,
      city_name: values.city_id,
      gender: values.gender,
      cart: cartList,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const orderDataByUser = (token, orderNo) => {
  return axios.get(
    `${API_URL}order/history/detail?id=${orderNo}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const movedToCart = (token, pId, uId) => {
  return axios.post(
    API_URL + "wishlist/to-cart",
    {
      product_id: pId,
      user_id: uId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const UserListService = {
  pageHits,
  loginUser,
  forgotPassUser,
  resetToekenVerify,
  resetUserPassword,
  getHighlitedProducts,
  getRelatedProducts,
  getProductsCatagory,
  contentGetMainSlider,
  getProductsById,
  addInWish,
  listWish,
  listOrdered,
  // wishCount,
  // wishAddedIdz,
  // getCartCounter,
  emptyWishList,
  deleteById,
  // addInCart,
  // listCart,
  // deleteCartById,
  // emptyCartList,
  // quantityDecrease,
  getProfile,
  updateProfileByType,
  makeContact,
  getAllStates,
  citiesByStateId,
  updateProfileImage,
  listProductByType,
  listheaderCategories,
  listProductByCategory,
  searchInHeader,
  searchInAdvance,
  checkEligibillity,
  placeOrder,
  movedToCart,
  orderDataByUser,
};

export default UserListService;
