import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Redux";
import profile from "../../../Images/profile.png";
import heart from "../../../Images/heart.png";
import gift from "../../../Images/gift.png";
import logOut from "../../../Images/logout.png";
import pkg from "../../../Images/package.png";
import cartPic from "../../../Images/shopping-cart.png";
import UserListService from "../../../services/user-list.service";

export default function MobileMenuFooter() {
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  const locat = useLocation();

  const {
    wishlist_items_count,
    cartCount,
    wishIdz,
    wishList,
    userAccessToken,
  } = useSelector((state) => state.stateVals);

  const logout = async (e) => {
    e.preventDefault();

    await userActions.logOut("userLogOut");
    setTimeout(() => {
      window.location.href = "/";
    }, 200);
  };

  const getListing = async () => {
    if (
      wishIdz?.length &&
      !wishList?.length &&
      window.location.pathname !== "/wishlist"
    ) {
      const res = await UserListService.listWish(userAccessToken);
      if (res?.data?.response?.length) {
        userActions.addWishList(res.data.response, "wishListAdd");
      } else {
        await userActions.wishCounter(
          {
            userAccessToken: userAccessToken,
            count: 0,
            idz: [],
          },
          "wishCount"
        );
      }
    } else if (
      (wishlist_items_count > 0) & !wishIdz?.length &&
      !wishList?.length &&
      window.location.pathname !== "/wishlist"
    ) {
      await userActions.wishCounter(
        {
          userAccessToken: userAccessToken,
          count: 0,
          idz: [],
        },
        "wishCount"
      );
    }
    // if (
    //   cartCount > 0 &&
    //   !cartList?.length &&
    //   window.location.pathname !== "/cart"
    // ) {
    //   const responseCart = await UserListService.listCart(userAccessToken);
    //   userActions.addToCart(responseCart.data.response, "cartAdd");
    // }
  };

  useLayoutEffect(() => {
    getListing();
  }, []);
  useLayoutEffect(() => {
    const loadHighlited = async () => {
      try {
        const response = await UserListService.getHighlitedProducts(
          userAccessToken
        );

        const response2 = await UserListService.getProductsCatagory(
          userAccessToken
        );

        const response3 = await UserListService.contentGetMainSlider(
          "rewards",
          "active",
          userAccessToken
        );

        await userActions.highlitedProduct(
          {
            featured: response.data.data.featured,
            trending: response.data.data.trending,
            new: response.data.data.new,
            top_selling: response.data.data.top_selling,
            catoryListing: response2.data.data,
            slider: response3?.data?.data?.response,
          },
          "highlited"
        );
      } catch (error) {
        console.log(error);
      }
    };
    if (window.location.pathname !== "/view-reward") {
      loadHighlited();
    }
  }, []);
  return (
    <div className="container">
      <div className="top-logo-box-m">
        <div className="navigation">
          <ul>
            <li
              className={`list ${
                locat.pathname === "/view-reward" ? "active" : ""
              }`}
              data-color="#dc143c"
            >
              <Link to="/view-reward">
                <span className="icon">
                  <img src={gift} alt="" />
                </span>
                <span className="title">Rewards</span>
              </Link>
            </li>
            <li
              className={`list ${
                locat.pathname === "/order-history" ? "active" : ""
              }`}
              data-color="#dc143c"
            >
              <Link to="/order-history">
                <span className="icon">
                  <img src={pkg} alt="" />

                  {/* <i className="bi bi-box-seam"></i> */}
                </span>
                <span className="title">Orders</span>
              </Link>
            </li>
            <li
              className={`list ${
                locat.pathname === "/wishlist" ? "active" : ""
              }`}
              data-color="#0fbcf9"
            >
              <Link to="/wishlist">
                <span className="icon">
                  <img src={heart} alt="" />
                </span>
                <span className="title">
                  Wishlist ({wishlist_items_count || 0})
                </span>
              </Link>
            </li>
            <li
              className={`list ${locat.pathname === "/cart" ? "active" : ""}`}
              data-color="#05c46b"
            >
              <Link to="/cart">
                <span className="icon">
                  <img src={cartPic} alt="" />
                </span>
                <span className="title">Cart ({cartCount || 0})</span>
              </Link>
            </li>
            <li
              className={`list ${
                locat.pathname === "/my-account" ? "active" : ""
              }`}
              data-color="#3c40c6"
            >
              <Link to="/my-account">
                <span className="icon">
                  <img src={profile} alt="" />
                </span>
                <span className="title">User</span>
              </Link>
            </li>
            <li className="list" data-color="#ffa801">
              <a onClick={logout} href="/">
                <span className="icon">
                  <img src={logOut} alt="" />
                </span>
                <span className="title">Logout</span>
              </a>
            </li>
            <div className="indicator"></div>
          </ul>
        </div>
      </div>
    </div>
  );
}
