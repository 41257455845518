export const logIn = (states, type) => {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: states,
    });
  };
};

export const logOut = (type) => {
  return (dispatch) => {
    dispatch({
      type: type,
    });
  };
};

export const wishCounter = (states, type) => async (dispatch) => {
  dispatch({
    type: type,
    payload: { count: states.count, idz: states.idz },
  });
};

export const cartCounter = (states, type) => async (dispatch) => {
  dispatch({
    type: type,
    payload: states,
  });
};

export const highlitedProduct = (states, type) => async (dispatch) => {
  dispatch({
    type: type,
    payload: states,
  });
};

export const addWishList = (states, type) => async (dispatch) => {
  dispatch({
    type: type,
    payload: states,
  });
};
export const addCategories = (states, type) => async (dispatch) => {
  dispatch({
    type: type,
    payload: states,
  });
};

export const addToCart = (states, type) => async (dispatch) => {
  dispatch({
    type: type,
    payload: states,
  });
};
